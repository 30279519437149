import React from 'react'
import {graphql} from "gatsby";
import Layout from "../layouts";

const ErrorPage = ({
       data: {
           page,
           navigation
       },
       pageContext: {
           locale
       }
   }) => {
    return (
        <Layout locale={locale} navigation={navigation} type='homepage' page={page}>
            <div className='error-404'>
                <div className="container">
                    <h1 className='color--kraaijeveld'><span>{page.pageTitle}</span> {page.pageSubtitle}</h1>
                </div>
            </div>
        </Layout>
    );
}

export default ErrorPage

export const query = graphql`
    query($locale: String, $contentful_id: String) {
        site {
            siteMetadata {
                languages {
                    defaultLangKey
                    langs
                }
            }
        }
        page: contentfulSuccessPage( contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
            pageTitle
            pageSubtitle
        }
        navigation: contentfulNavigation (node_locale: {eq: $locale}) {
            ...NavigationFragment
        }  
        cta: allContentfulCallToAction(filter: {node_locale: {eq: $locale}, pageLocation: {eq: "register"}}) {  
            edges {
                node {
                    ...CallToActionFragment
                }
            }
        }
        slider: allContentfulSlider(filter: {node_locale: {eq: $locale}}) {
            edges {
                node {
                    ...SliderFragment
                }
            }
        }
    }
`
